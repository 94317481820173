<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceList.sn')"
        v-model.trim="params.sn"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" @click="replaceData" plain>{{ $t('query') }}</el-button>
    </div>
    <div style="margin-bottom:15px">
      <el-radio-group v-model="status">
        <el-radio v-for="item in statusOptions" :key="item.label" :label="item.value">{{
          item.label
        }}</el-radio>
      </el-radio-group>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="200"
          prop="spName"
          :label="$t('publishOta.boundCustomer')"
        />
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  props: ['faterItem'],
  mixins: [watchParamsGetData],
  data() {
    return {
      status: 1,
      detailVisible: false,
      firstIn: true,
      currentItem: null,
      statusOptions: [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.downloaded'),
          value: 2
        },
        {
          label: this.$t('operateList.alreadyInstalled'),
          value: 3
        }
      ],
      tableData: []
    }
  },
  watch: {
    status(val) {
      this.getData()
    }
  },
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        this.params.versionPublishId = this.faterItem.versionPublishId
        this.params.status = this.status
        const res = await this.$api.otaManagement.pageDeviceByVersionPublishId(this.params)

        if (res && res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
img {
  width: 60px;
  height: 60px;
}
.table-wrap {
  min-height: 230px;
  .row {
    display: flex;

    flex-wrap: wrap;
    img {
      margin-right: 10px;
    }
    & > div {
      border: 1px solid #ccc;
      margin: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .name {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0;
      }

      & + div {
        margin-left: 10px;
      }
    }
  }
}
</style>
