<template>
  <page v-loading="loading" :borderBottom="true">
    <div slot="headerLeft">
      <span class="spanItem active">{{$t('publishOta.upgradeDetails')}}</span>
    </div>
    <div slot="headerRight" class="flex" style="align-items: center;">
      <el-button size="small" type="primary" round @click="$router.go(-1)">{{$t('backList')}}</el-button>
    </div>

    <div class="mainWrap">
      <div class="row">
        <span class="label">{{$t('publishOta.ItemsPublish')}}:</span>
        <div class="content">
          <div class="itemInfo">
            <div class="name" :title="currentItem.projectName">{{ currentItem.projectName }}</div>
            <div class="commonItem">
              <span class="left">{{$t('deviceList.deviceType')}}：</span>
              <span class="right">
                {{ currentItem.modelName }}
              </span>
            </div>
            <div class="commonItem">
              <span class="left">{{$t('otaList.platform')}}：</span>
              <span class="right">
                {{ platformStr[currentItem.platform] || '-' }}
              </span>
            </div>
            <div class="commonItem">
              <span class="left">{{$t('deviceRoom.operatingSystem')}}：</span>
              <span class="right">
                {{ systemStr[currentItem.operatingSystem] || '-' }}
              </span>
            </div>
            <div class="commonItem">
              <span class="left">{{$t('otaList.equipmentNumber')}}：</span>
              <span class="right">
                {{ currentItem.deviceNumber }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="label">{{$t('publishOta.upgradeMethod')}}:</span>
        <div class="content">
          <span style="margin-right:20px;">{{ item.publishWayStr }}</span>
          <span v-if="currentItem.firmwareVersion">{{$t('deviceList.versionNo')}}{{ currentItem.firmwareVersion }}</span>
        </div>
      </div>
      <div class="row">
        <span class="label">{{$t('operateList.associatedDevices')}}:</span>
        <span>{{ currentItem.deviceNumber || 0 }}{{$t('adminHomePage.tower')}}</span>
        <el-link @click="downloadList" type="primary">{{$t('operateList.downloadDeviceList')}}</el-link>
      </div>
    </div>

    <div class="bottomWrap">
      <device-view :faterItem="currentItem" />
    </div>
  </page>
</template>

<script>
import DeviceView from './components/DeviceView'


export default {
  components: {
    DeviceView
  },
  data() {
    return {
      currentView: 'DeviceView',
      loading: false,
      item: {},
      list: [],
      systemStr: {
        1: 'Android',
        2: 'Linux',
        3: 'QNX',
        4: 'RTOS',
        5: 'Windows'
      },
      platformStr: {
        1: 'RK3288',
        2: 'RK3288_5.1',
        3: 'RK3399_7.1'
      },
      types:[
        {
          value: 1,
          label:this.$t('publishOta.sourceVersion')
        },
        {
          value: 2,
          label: this.$t('publishOta.addSingleSNNumber')
        },
        {
          value: 3,
          label:this.$t('publishOta.batchAddSNNumber')
        }
      ]
    }
  },
  created() {
    if (this.currentItem) {
      this.getServiceList()

      const item = { ...this.currentItem }
      const e = this.types.find(e => e.value === item.publishWay)
      item.publishWayStr = e.label
      this.item = item
    }
  },
  computed: {
    currentItem() {
      return (
        sessionStorage.getItem('currentAppDetail') &&
        JSON.parse(sessionStorage.getItem('currentAppDetail'))
      )
    },
    operateName() {
      return this.operateType === 2 ? this.$t('publishApp.uninstall'):this.$t('publishApp.release')
    },
    operateType() {
      return +this.$route.params.type
    }
  },
  methods: {
    async getServiceList() {
      const res = await this.$api.systemManagement.spList({ pageSize: -1 })

      if (res && res.result.list) {
        const spNameMap = new Map()

        res.result.list.forEach(item => spNameMap.set(item.spId, item.name))

        const publishObjectIds = (this.currentItem.publishObjectIds || '').split(',')
        console.log(publishObjectIds)
        this.list = publishObjectIds.map(id => spNameMap.get(+id))
      }
    },
    async downloadList() {
      const loading = this.$loading()

      try {
        const res = await this.$api.otaManagement.downloadList({
          versionPublishId: this.currentItem.versionPublishId
        })

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    },
    async getGrouplName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getGrouplName(publishObjectIds)
        this.list = res.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async getDeviceName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getDeviceName({ snList: publishObjectIds })
        this.list = res.result.map(item => {
          return {
            label: item.sn,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async getModelName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getModelName(publishObjectIds)
        this.list = res.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-button {
  padding: 6px 15px;
}

.mainWrap {
  .itemInfo{
    min-width:200px;
    width: auto;
  }
  .row {
    display: flex;

    img {
      width: 50px;
      height: 50px;
    }

    & + .row {
      margin-top: 18px;
      display: flex;
      align-items: center;
    }
    .label {
      width: 120px;
      flex-shrink: 0;
    }
    .row {
      flex-wrap: wrap;
      margin-top: 10px;
      span + span {
        margin-left: 2px;
      }
    }
  }
  ::v-deep .el-link {
    margin-left: 5px;
  }
}

.bottomWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
  .switchHead {
    display: flex;
    justify-content: center;
  }
}
</style>
