<template>
  <el-dialog
    :close-on-click-modal="false"
    v-loading="loading"
    v-elDragDialog
    :title="$t('publishOta.selectItemsPublish')"
    :visible.sync="show"
  >
    <div class="header">
      <div class="left">
        <el-input v-model.trim="projectName" :placeholder="$t('otaList.enterProjectName')" />
        <el-button type="primary" plain @click="search">{{ $t('search') }}</el-button>
      </div>
    </div>

    <div class="content">
      <div class="row" v-for="item in list" :key="item.id">
        <div class="itemInfo">
          <div class="name">{{ item.projectName }}</div>
          <div class="commonItem">
            <span class="left">{{ $t('deviceList.deviceType') }}：</span>
            <span class="right">
              {{ item.modelName }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('otaList.platform') }}：</span>
            <span class="right">
              {{ platformStr[item.platform] || '-' }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('deviceRoom.operatingSystem') }}：</span>
            <span class="right">
              {{ systemStr[item.operatingSystem] || '-' }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('otaList.equipmentNumber') }}：</span>
            <span class="right">
              {{ item.deviceNumber }}
            </span>
          </div>
        </div>
        <div class="versions">
          <el-checkbox-group
            @change="
              val => {
                changeHandler(val, item)
              }
            "
            :disabled="
              currentProject.projectId !== item.projectId && currentProject.projectId !== ''
            "
            v-model="item.checks"
          >
            <el-checkbox
              :disabled="!!(item.checks.length && !item.checks.includes(subItem.versionId))"
              v-for="subItem in item.children"
              :key="subItem.versionId"
              :label="subItem.versionId"
            >
              <div class="version">
                <span>V{{ subItem.versionName }}</span>
                <span>{{ subItem.fileSize }}</span>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      projectName: '',
      currentProject: {
        projectId: ''
      },
      loading: false,
      systemStr: {
        1: 'Android',
        2: 'Linux',
        3: 'QNX',
        4: 'RTOS',
        5: 'Windows'
      },
      platformStr: {
        1: 'RK3288',
        2: 'RK3288_5.1',
        3: 'RK3399_7.1'
      },
      list: []
    }
  },
  async created() {
    await this.getProjectList()
  },
  methods: {
    search() {
      const val = this.projectName
      if (!val) {
        this.list = [...this.backUpData]
        return
      }
      const str = val.toLowerCase()
      const targetItem = this.list.find(_ => _.projectName.toLowerCase() === str)
      this.list = targetItem ? [targetItem] : []
    },
    changeHandler(val, item) {
      if (val.length) {
        this.currentProject = item
      } else {
        this.currentProject = {
          projectId: ''
        }
      }
    },
    async getListVersionByProjectId() {
      try {
        const res = await this.$api.otaManagement.listVersionByProjectId({
          projectId: this.form.projectInfos[0].projectId
        })
        let item = {}

        if (res.result) {
          this.sourceVersionItems = res.result.map(item => ({
            label: item.versionName,
            value: item.versionId
          }))
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getProjectList() {
      try {
        const res = await this.$api.otaManagement.allproject()

        if (res.result) {
          let list = res.result || []

          const promises = list.map(item => {
            const { projectId } = item
            return this.$api.otaManagement.listVersionByProjectId({ projectId })
          })
          const ress = await Promise.all(promises)
          ress.forEach((item, index) => {
            const versionList = []

            list[index].checks = []

            list[index].children = (item.result || []).reduce((arr, item, index) => {
              if (!versionList.includes(item.versionName)) {
                versionList.push(item.versionName)
                item.fileSize = this.$utils.bytesToSize(item.fileSize)
                arr.push(item)
              }
              return arr
            }, [])
          })

          this.list = list
          this.backUpData = list
        }
      } catch (err) {
        console.log(err)
      }
    },

    submit() {
      const data = this.list.map(item => {
        return item.checks.map(versionId => {
          return {
            project: this.currentProject,
            versionId: versionId
          }
        })
      })
      this.$emit('selectApp', data.flat()[0])
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}

.content {
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  & > .row {
    & + .row {
      margin-top: 15px;
    }
  }
  .row {
    display: flex;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .versions {
    display: flex;
    flex-wrap: wrap;
    .version {
      display: flex;
      flex-direction: column;
    }
    ::v-deep .el-checkbox {
      margin-bottom: 10px;
    }
    .hideLabel {
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
  }
}
</style>
