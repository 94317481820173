<template>
  <page v-loading="loading">
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('otaList.projectName')"
        v-model.trim="params.projectName"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        type="primary"
        round
        icon="iconxinzeng"
        @click="
          currentItem = null
          visible = true
        "
        >{{ $t('otaList.addProject') }}</c-button
      >
    </div>

    <div class="mainCot" v-scroll="loadmore">
      <template v-if="projectList.length">
        <el-card class="listItem" v-for="item in projectList" :key="item.appId">
          <h3>{{ item.projectName }}</h3>
          <div class="commonItem">
            <span class="left">{{ $t('deviceList.deviceType') }}：</span>
            <span class="right">
              {{ item.modelName }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('otaList.platform') }}：</span>
            <span class="right">
              {{ platformStr[item.platform] || '-' }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('deviceRoom.operatingSystem') }}：</span>
            <span class="right">
              {{ systemStr[item.operatingSystem] || '-' }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('otaList.equipmentNumber') }}：</span>
            <span class="right">
              {{ item.deviceNumber }}
            </span>
          </div>

          <div class="el-card-bottom">
            <el-button size="small" type="primary" @click="toVersionManage(item)">{{
              $t('otaList.versionManagement')
            }}</el-button>
            <el-button size="small" type="primary" @click="updateHandler(item)">{{
              $t('edit')
            }}</el-button>
            <el-button size="small" type="danger" @click="delHandler(item)">{{
              $t('delete')
            }}</el-button>
          </div>
        </el-card>
      </template>
      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <add-project-modal
      @getData="replaceData"
      :visible.sync="visible"
      :modelList="modelList"
      :current-item="currentItem"
      v-if="visible"
    />
    <project-detail-modal :project-info="currentItem" :visible.sync="visible2" v-if="visible2" />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddProjectModal from './components/AddProjectModal'
import ProjectDetailModal from './components/ProjectDetailModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddProjectModal,
    ProjectDetailModal
  },
  data() {
    return {
      visible: false,
      visible2: false,
      projectList: [],
      targetObj: {},
      modelList: [],
      systemStr: {
        1: 'Android',
        2: 'Linux',
        3: 'QNX',
        4: 'RTOS',
        5: 'Windows'
      },
      platformStr: {
        1: 'RK3288',
        2: 'RK3288_5.1',
        3: 'RK3399_7.1'
      },
      currentItem: null
    }
  },
  created() {
    this.getModelList()
  },
  methods: {
    async getModelList() {
      try {
        const res = await this.$api.deviceManagement.modelList({ pageSize: -1 })
        if (res) {
          const targetArr = []
          const targetObj = {}

          if (res.result && res.result.list) {
            res.result.list.forEach(item => {
              targetArr.push({
                label: item.name,
                value: item.id
              })

              targetObj[item.id] = item.name
            })
          }

          this.modelList = targetArr
          this.targetObj = targetObj
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadmore() {
      if (this.total < this.params.pageSize) {
        return
      }
      ++this.params.currentPage
      this.getData()
    },
    detailHandler(item) {
      this.visible2 = true
      this.currentItem = item
    },
    async delHandler(row) {
      try {
        await this.$confirm(this.$t('otaList.deleteItem'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.otaManagement.projectDelete({ projectId: row.projectId })
        this.$message.success(this.$t('deleteSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    updateHandler(item) {
      this.visible = true
      this.currentItem = item
    },
    toVersionManage(item) {
      this.$router.push({
        name: 'versionManagementOta',
        params: item
      })
    },
    async getData() {
      try {
        if (this.noMore && this.params.currentPage !== 1) return

        this.loading = true
        const res = await this.$api.otaManagement.projectList(this.params)

        if (res.result) {
          const list = res.result.list || []

          let targetList = this.params.currentPage === 1 ? list : this.projectList.concat(list)
          this.projectList = [...targetList]
          if (!list.length && this.params.currentPage !== 1) this.noMore = true
          this.total = res.result.total
        } else {
          this.projectList = []
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mainCot {
  position: relative;
  min-height: 400px;
  overflow: auto;
  height: 0;
  flex: 1;
  .listItem {
    width: 30%;
    display: inline-block;
    margin-right: 28px;
    margin-bottom: 20px;
    padding-left: 10px;

    h3 {
      margin-bottom: 20px;
    }

    ::v-deep .el-card__body {
      display: flex;
      flex-direction: column;

      div:first-child {
        display: flex;
        margin-bottom: 20px;
        h3 {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .commonItem {
    display: flex;
    margin-bottom: 20px;
    .left {
      min-width: 80px;
      flex-shrink: 0;
    }
    .right {
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        margin-right: 20px;
        word-break: break-word;
      }
    }
  }
}
</style>
