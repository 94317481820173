<template>
  <div>
    <el-dialog
      v-elDragDialog
      width="700px"
      :title="isCreated ? $t('otaList.addVersion') : $t('otaList.editVersion')"
      :close-on-click-modal="false"
      :visible.sync="show"
      :before-close="close"
      class="add-app"
    >
      <el-form
        v-loading="loading"
        :element-loading-text="uploadMessage"
        :model="form"
        :rules="rules"
        size="small"
        ref="form"
        label-width="100px"
      >
        <el-form-item :label="$t('otaList.versionName')" prop="versionName">
          <el-input v-model.trim="form.versionName" :placeholder="$t('otaList.enterVersionName')" />
        </el-form-item>

        <el-form-item :label="$t('deviceList.versionNo')" prop="firmwareVersion">
          <el-input
            v-model.trim="form.firmwareVersion"
            @input="form.firmwareVersion = form.firmwareVersion.replace(/[^0-9]/g, '')"
            :placeholder="$t('otaList.enterVersion')"
          />
        </el-form-item>

        <el-form-item v-if="isCreated" :label="$t('otaList.OTAFiles')" prop="otaFile">
          <el-upload
            action="null"
            :on-change="file => onFilechange(file, 'otaFile')"
            :on-remove="() => (form.otaFile = '')"
            :show-file-list="true"
            :multiple="false"
            :auto-upload="false"
            ref="fileUpload"
            name="xls"
          >
            <el-button @click="$refs.fileUpload.clearFiles()" type="primary">{{
              $t('deviceModal.selectFile')
            }}</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item :label="$t('otaList.describe')" prop="versionDescription">
          <el-input type="textarea" rows="4" resize="none" v-model="form.versionDescription" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">{{ $t('cancel') }}</el-button>
        <el-button :disabled="loading === true" type="primary" @click="submitForm(form)">{{
          $t('save')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { PLATFORM } from '@/lib/constant'
import axios from 'axios'

export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['modelList', 'projectId'],
  data() {
    const rules = {
      versionName: [
        { required: true, message: this.$t('otaList.enterVersionName') },
        { max: 40, message: this.$t('otaList.versionLength'), trigger: 'blur' }
      ],

      firmwareVersion: [{ required: true, message: this.$t('otaList.enterVersion') }],
      otaFile: [{ required: true, message: this.$t('otaList.pleaseOTAFiles') }]
    }

    return {
      loading: false,
      uploadMessage: 0,
      form: {
        versionName: '',
        firmwareVersion: '',
        versionDescription: '',
        otaFile: ''
      },
      cancels: null,
      rules
    }
  },
  created() {
    if (this.currentItem) {
      this.form.versionName = this.currentItem.versionName
      this.form.firmwareVersion = this.currentItem.firmwareVersion
      this.form.versionDescription = this.currentItem.versionDescription
    }
  },
  methods: {
    close() {
      this.show = false
      this.uploadMessage = 0
      if (this.cancels) {
        this.cancels()
      }
    },
    onFilechange(file, key) {
      this.form[key] = file.raw
    },
    submitForm(data) {
      const CancelToken = axios.CancelToken
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          const formData = new FormData()

          for (const i in this.form) {
            this.form[i] && formData.append(i, this.form[i])
          }

          formData.append('projectId', this.projectId)

          try {
            if (this.currentItem) {
              await this.$api.otaManagement.versionUpdate({
                ...this.form,
                versionId: this.currentItem.versionId
              })
              this.$message.success(this.$t('editSuccess'))
              this.$emit('getData')
              this.show = false
            } else {
              const apiType = PLATFORM === 'admin' ? '/zuul/admin/api' : '/sp/api'

              let url = `/scpserver${apiType}/apk/uploadOta`

              if (process.env.NODE_ENV === 'development') {
                url = url.replace('/scpserver', '')
              }

              const res = await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  system: PLATFORM === 'admin' ? 4 : 5
                },
                timeout: 20000000,
                cancelToken: new CancelToken(c => {
                  this.cancels = c
                }),
                onUploadProgress: progressEvent => {
                  if (progressEvent && progressEvent.loaded) {
                    let complete = (((progressEvent.loaded / progressEvent.total) * 100) | 0) + '%'
                    this.uploadMessage = complete
                  }
                }
              })

              if (res.data.code !== 'OK') {
                this.$message({
                  message: res.data.summary || this.$t('systemError'),
                  type: 'error',
                  duration: 3 * 1000
                })
              } else {
                this.$message.success(this.$t('addSuccess'))
                this.$emit('getData')
                this.show = false
              }
            }
          } catch (e) {
            console.log(e)
            this.$message({
              message: res.data.summary || this.$t('systemError'),
              type: 'error',
              duration: 3 * 1000
            })
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner i {
  font-size: 30px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
::v-deep .el-upload--picture-card {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label i {
  position: absolute;
  left: 14px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .el-select {
  width: 100%;
}
.avatar-uploader .el-upload:hover {
  border-color: #ef2b23;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.flex {
  display: flex;
  align-items: center;

  .tips {
    line-height: 20px;
    margin-left: 20px;
  }
}
</style>
