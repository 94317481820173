<template>
  <div class="versionManagement">
    <div class="select-header">
      <div class="select-project">
        <span class="label">{{ $t('otaList.selectProject') }}</span>
        <el-select
          v-model="targetItem"
          @change="changeHandler"
          value-key="value"
          :placeholder="$t('otaList.selectProject')"
        >
          <el-option
            v-for="item in projectList"
            :key="item.value"
            :label="item.label"
            :value="item"
          />
        </el-select>

        <div class="item" v-if="targetItem.modelName">
          <span class="label">{{ $t('deviceList.deviceType') }}：</span>
          <span class="value">{{ targetItem.modelName }}</span>
        </div>

        <div class="item" v-if="targetItem.deviceNumber">
          <span class="label">{{ $t('otaList.equipmentNumber') }}：</span>
          <span class="value">{{ targetItem.deviceNumber }}</span>
        </div>
      </div>
    </div>
    <div class="line-bg"></div>
    <page>
      <div slot="headerLeft">
        <el-input
          v-model.trim="params.firmwareVersion"
          :placeholder="$t('deviceList.versionNo')"
          @keyup.enter.native="getData"
        />
        <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      </div>

      <div slot="headerRight">
        <c-button
          type="primary"
          round
          icon="iconxinzeng"
          @click="
            currentItem = null
            visible = true
          "
          >{{ $t('otaList.addVersion') }}</c-button
        >
      </div>

      <div class="table-wrap">
        <el-table :data="tableData" border v-loading="loading" height="100%">
          <el-table-column
            show-overflow-tooltip
            prop="firmwareVersion"
            :label="$t('deviceList.versionNo')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="versionName"
            :label="$t('otaList.versionName')"
          />
          <el-table-column show-overflow-tooltip prop="versionId" :label="$t('index')" />
          <el-table-column
            show-overflow-tooltip
            prop="createTime"
            :label="$t('deviceList.createdTime')"
          >
            <template slot-scope="{ row }">
              {{ $utils.formatTime(row.createTime) }}
            </template>
          </el-table-column>

          <el-table-column show-overflow-tooltip :label="$t('otaList.fileSize')">
            <template slot-scope="{ row }">
              {{ $utils.bytesToSize(row.fileSize) }}
            </template>
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            prop="versionDescription"
            :label="$t('otaList.describe')"
          />

          <el-table-column fixed="right" :label="$t('operation')">
            <template slot-scope="{ row }">
              <div class="operateRow">
                <el-button type="text" @click="editHandler(row)">{{
                  $t('edit')
                }}</el-button>
                <el-button type="text" @click="delHandler(row)">{{
                  $t('delete')
                }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination
        slot="pagination"
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.currentPage"
        @change="getData"
      />

      <add-version-modal
        @getData="replaceData"
        :projectId="params.projectId"
        :visible.sync="visible"
        :current-item="currentItem"
        v-if="visible"
      />
    </page>
  </div>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddVersionModal from './components/AddVersionModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddVersionModal
  },
  data() {
    return {
      visible: false,
      currentItem: {},
      targetItem: {},
      tableData: [],
      projectList: []
    }
  },
  created() {
    this.getProjectList()
  },
  methods: {
    async getProjectList() {
      try {
        const res = await this.$api.otaManagement.allproject()
        let item = {}

        if (res.result) {
          this.projectList = res.result.map(item => ({
            label: item.projectName,
            value: item.projectId,
            modelName: item.modelName,
            deviceNumber: item.deviceNumber
          }))
          this.params.projectId = this.$route.params.projectId || res.result[0].projectId

          if (JSON.stringify(this.$route.params) !== '{}') {
            item = this.$route.params
          } else {
            item = res.result[0]
          }

          this.targetItem = {
            label: item.projectName,
            value: item.projectId,
            modelName: item.modelName,
            deviceNumber: item.deviceNumber
          }

          this.getData()
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getData(loading = true) {
      if (!this.params.projectId) return

      try {
        this.loading = true
        const res = await this.$api.otaManagement.versionList(this.params)

        if (res.result) {
          this.tableData = res.result.list || []
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    async delHandler(row) {
      try {
        await this.$confirm(this.$t('otaList.deleteThisVersion'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.otaManagement.versionDelete({ versionId: row.versionId })
        this.$message.success(this.$t('deleteSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    editHandler(item) {
      this.visible = true
      this.currentItem = item
    },
    changeHandler(obj) {
      this.params.projectId = obj.value
      this.replaceData()
    }
  }
}
</script>

<style scoped lang="scss">
.versionManagement {
  height: 100%;
  display: flex;
  flex-direction: column;
  .select-header {
    .select-project,
    .item {
      display: flex;
      align-items: center;
      .label {
        color: #515151;
        margin-right: 6px;
      }
    }

    .item {
      margin-left: 30px;
    }
  }

  .line-bg {
    width: 109%;
    background: #f5f7fa;
    height: 15px;
    margin: 12px 0 0 -40px;
  }

  img {
    width: 50px;
    height: 50px;
  }
  .expandWrap {
    ::v-deep .el-table th,
    .el-table tr {
      background-color: #f5f7fa;
    }
    .el-table__body-wrapper {
      overflow: auto;
    }
  }
}
</style>
